import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Space,
  Typography,
  Image,
  Card,
  Button,
  Input,
} from "antd";
import DEFAULT_ICON from "../../assets/app-drawer/default_icon.svg";
import { APPS_DIRECTORY_MESG, ACCESS_DENIED_MESSAGE } from "../../utils/Const";
import Loading from "../../common/loader";
import {
  RootStateOrAny,
  shallowEqual,
  useSelector,
  useDispatch,
} from "react-redux";
import NothingToShow from "../../common/NothingToShow";
import { fetchAppsDetails } from "../../actions/AppsDetailsAction";
import Loader from "../../common/loader";
import { useParams } from "react-router-dom";
interface dataTypes {
  name: string;
  description: string;
  keyword: string;
  to: string;
  icon: string;
  section: string;
  isInternal: boolean;
  disabled: boolean;
  last?: boolean;
  navigation:
    | {
        key: string;
        value: string;
        isEnable?: boolean;
      }[]
    | [];
}

export default function Directory() {
  const [appData, setAppData] = useState<any>(null);
  const [loader, setloader] = useState(true);
  const dispatch = useDispatch();
  const { tenantId } = useParams();

  const { appsDetails, appsDetailsError } = useSelector(
    (state: RootStateOrAny) => ({
      appsDetails: state.appsDetailsReducer.appsDetails,
      appsDetailsError: state.appsDetailsReducer.error,
    }),
    shallowEqual
  );

  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("theme")
  );
  const getCurrentTheme = () => {
    setCurrentTheme(localStorage.getItem("theme"));
  };

  useEffect(() => {
    if (!appsDetails) {
      dispatch(fetchAppsDetails());
    }
  }, []);

  useEffect(() => {
    if (appsDetails) {
      setAppData(appsDetails);
      setloader(false);
    } else if (appsDetailsError) {
      setAppData(appsDetails);
      setloader(false);
    }
  }, [appsDetails, appsDetailsError]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const [query, setQuery] = useState<any>("");

  let search_parameters;

  const search = () => {
    search_parameters = Object.keys(Object.assign({}, ...appData));
    return appData.filter((data: any) =>
      search_parameters.some((parameter) => {
        if (data[parameter]) {
          return data[parameter]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        }
      })
    );
  };

  let firstLetter = [];
  if (appData) {
    firstLetter = search().reduce((r: any, e: any) => {
      let group = e.name[0];
      if (!r[group])
        r[group] = {
          group,
          apps: [e],
        };
      else r[group].apps.push(e);
      return r;
    }, {});
  }

  let resultFirstLetter = Object.values(firstLetter || null);

  const getRedirectionUrl = (item) => {
    if (item.absoluteUrl) {
      return item.absoluteUrl;
    } else if (item.path) {
      return `${window.location.origin}${item.path ? item.path : "/"}`;
    } else {
      return null;
    }
  };

  const getHrefWithTenant = (item: any) => {
    let finalHref = "";
    const path = getRedirectionUrl(item);
    if (path.endsWith("/")) {
      finalHref = `${path}${tenantId}`;
    } else {
      finalHref = `${path}/${tenantId}`;
    }
    return finalHref;
  };

  const getSubHrefWithTenant = (path: any) => {
    const finalPath = path.split("/metis");
    if (path.startsWith("/metis")) {
      return `${window.location.origin}/metis/${tenantId}${finalPath[1]}}`;
    } else {
      return `${window.location.origin}${finalPath}/${tenantId}`;
    }
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <div className="directory">
          <Row>
            <Col
              span={24}
              className="directory-anchor-links"
              style={{
                position: "sticky",
                top: "62px",
                padding: "16px 0 0",
                zIndex: 1,
              }}
            >
              <Space
                size={[24, 24]}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  minHeight: 40,
                }}
                className="apps-nav-header"
              >
                <Space size={[24, 24]}>
                  {resultFirstLetter.map((letter: any) => {
                    return (
                      <Button
                        onClick={() => handleClickScroll(letter.group)}
                        key={letter.group}
                        type="text"
                        size="large"
                        style={{
                          textTransform: "lowercase",
                          cursor: "pointer",
                          color: "#1890ff",
                        }}
                      >
                        {letter.group}
                      </Button>
                    );
                  })}
                </Space>
                <Input
                  type="search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search"
                  allowClear
                />
              </Space>
              <Divider style={{ margin: "16px 0" }} />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "16px",
              }}
            >
              {resultFirstLetter.map((letter: any, index: any) => {
                return (
                  <div key={letter.group + index} id={`${letter.group}`}>
                    <Row>
                      <Col span="2">
                        <div style={{ textAlign: "left" }}>
                          <Typography.Text
                            key={letter.group}
                            type="secondary"
                            style={{
                              textTransform: "lowercase",
                              fontWeight: "100",
                              fontSize: "72px",
                              lineHeight: "40px",
                              display: "inline-block",
                              textAlign: "center",
                              width: "56px",
                              opacity: "0.3",
                            }}
                          >
                            {letter.group}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span="22">
                        <Row gutter={[24, 24]}>
                          {letter.apps.map((item: any, idx: any) => {
                            return (
                              <Col
                                span="24"
                                key={"" + idx}
                                style={{ position: "relative" }}
                              >
                                {item.appType && item.appType !== "" && (
                                  <div className="app-type">{item.appType}</div>
                                )}
                                <Space
                                  key={item.name}
                                  align="start"
                                  size="large"
                                  direction="horizontal"
                                  className={
                                    item.disabled ? "div-bg-disabled" : ""
                                  }
                                >
                                  <Image
                                    height={42}
                                    width={42}
                                    src={
                                      item.icon &&
                                      item.icon.startsWith(
                                        "data:image/svg+xml;base64"
                                      )
                                        ? item.icon
                                        : DEFAULT_ICON
                                    }
                                    className="app-icon"
                                    preview={false}
                                  />
                                  <div>
                                    <div style={{ marginBottom: "4px" }}>
                                      {getRedirectionUrl(item) ? (
                                        <Typography.Link
                                          href={getHrefWithTenant(item)}
                                          target={
                                            !item.isInternal ? "_blank" : ""
                                          }
                                          rel="noopener noreferrer"
                                          style={{ fontSize: "16px" }}
                                          className="app-title-links"
                                          italic={item.disabled}
                                        >
                                          {item.title}{" "}
                                          {item.releaseLevel ? (
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                fontStyle: "italic",
                                                color: "#ccc",
                                              }}
                                            >
                                              ({item.releaseLevel})
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Typography.Link>
                                      ) : (
                                        <Typography.Text
                                          style={{ fontSize: "16px" }}
                                          className="app-title-links"
                                          italic={item.disabled}
                                        >
                                          {item.name}{" "}
                                          {item.releaseLevel ? (
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                fontStyle: "italic",
                                                color: "#ccc",
                                              }}
                                            >
                                              ({item.releaseLevel})
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Typography.Text>
                                      )}
                                    </div>

                                    <Space direction="vertical">
                                      <Typography.Text
                                        italic={item.disabled}
                                        style={{
                                          fontSize: "12px",
                                          color: "#666",
                                        }}
                                      >
                                        {item.description}
                                      </Typography.Text>
                                      <Space
                                        key={idx}
                                        direction="horizontal"
                                        split={
                                          <Divider
                                            type="vertical"
                                            style={{
                                              margin: 0,
                                              borderColor: "#ddd",
                                            }}
                                          />
                                        }
                                      >
                                        {item.navigation &&
                                        Array.isArray(item.navigation)
                                          ? item.navigation.map(
                                              (subItem: any, idx: number) => {
                                                return (
                                                  <Fragment key={idx}>
                                                    <Typography.Link
                                                      href={getSubHrefWithTenant(
                                                        subItem.value
                                                      )}
                                                      target="_blank"
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {subItem.key}
                                                    </Typography.Link>
                                                  </Fragment>
                                                );
                                              }
                                            )
                                          : null}
                                      </Space>
                                    </Space>
                                  </div>
                                </Space>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                    <Divider style={{ opacity: 0 }} />
                  </div>
                );
              })}
              {resultFirstLetter.length === 0 && (
                <Loader msg="No Result" isSmallLoading />
              )}
            </Col>
          </Row>
          {appData && appData.length === 0 && (
            <Space>
              {APPS_DIRECTORY_MESG}
              <a href={`${window.location.origin}/metis`}>{"Metis"}</a>
              <Divider type="vertical" style={{ margin: 0 }} />
              <a href={`${window.location.origin}/workench`}>{"Workench"}</a>
            </Space>
          )}
        </div>
      )}
    </>
  );
}
